import React from 'react';

import { Pagination } from 'wix-ui-tpa/Pagination';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';

import { ISearchResultsProps } from './SearchResults.types';
import { SearchInput } from '../SearchInput';
import { Spinner } from '../Spinner';
import { DemoContentNotification } from '../DemoContentNotification';
import { StatusLine } from '../StatusLine';
import { TabLine } from './TabLine';
import { FailedMessage } from '../ResultsStatus';
import { useRemount } from '../../hooks/useRemount';
import settingsParams from '../../../settingsParams';
import { Spec } from '../../../../../lib/specs';

import { st, classes } from './SearchResults.st.css';

const MAX_PAGES_TO_SHOW_IN_PAGINATION = {
  DESKTOP: 5,
  MOBILE: 3,
};

export const SearchResults: React.FC<ISearchResultsProps> = ({
  apiErrorDetails,
  buildPageUrl,
  facets,
  failed,
  isDemoContent = false,
  isLoading = false,
  isPaginationHidden,
  isSearchBarEnabled,
  sortProps,
  totalCount,
  visibleTabsWithTitles,
  ...props
}) => {
  const { isMobile, isEditorX } = useEnvironment();
  const { experiments } = useExperiments();
  const settings = useSettings();

  const paginationAlignment = settings.get(settingsParams.paginationAlignment);

  // This is a hack. It will be removed when issues regarding experiments in SSR are fixed
  const key = useRemount();

  return (
    <div
      key={key}
      data-hook="search-results-root"
      className={st(classes.root, {
        fluid: isEditorX,
        mobileView: isMobile,
        withDirection: experiments.enabled(Spec.WidgetRtl),
      })}
    >
      {isSearchBarEnabled && (
        <SearchInput
          clearLabel={props.searchClearLabel}
          onChange={props.onQueryChange}
          onClear={props.onQueryClear}
          onSubmit={props.onSubmit}
          placeholder={props.searchPlaceholder}
          value={props.searchQuery}
        />
      )}
      <div data-hook="content-wrapper" className={classes.contentWrapper}>
        <div
          data-hook="content"
          className={st(classes.content, { loading: isLoading })}
        >
          {visibleTabsWithTitles.length > 1 && (
            <TabLine
              activeTabIndex={props.activeTabIndex}
              items={visibleTabsWithTitles}
              onTabClick={props.onTabChange}
            />
          )}

          <div className={classes.layout}>
            {!!facets && !failed && (
              <div className={classes.facets}>{facets}</div>
            )}
            <div className={classes.items}>
              {failed ? (
                <FailedMessage
                  isMobile={isMobile}
                  apiErrorDetails={apiErrorDetails}
                />
              ) : (
                <StatusLine
                  activeTabIndex={props.activeTabIndex}
                  searchQuery={props.lastQuery}
                  sortProps={sortProps}
                  visibleTabsWithTitles={visibleTabsWithTitles}
                />
              )}

              {isDemoContent ? (
                <DemoContentNotification
                  text={props.demoContentNotificationText}
                />
              ) : null}
              {props.children}

              {props.currentPage && props.totalPages && !isPaginationHidden && (
                <div
                  className={st(classes.paginationWrapper, {
                    alignment: paginationAlignment,
                  })}
                >
                  <Pagination
                    totalPages={props.totalPages}
                    currentPage={props.currentPage}
                    maxPagesToShow={
                      isMobile
                        ? MAX_PAGES_TO_SHOW_IN_PAGINATION.MOBILE
                        : MAX_PAGES_TO_SHOW_IN_PAGINATION.DESKTOP
                    }
                    pageUrl={buildPageUrl}
                    onChange={props.onPageChange}
                    data-hook="pagination"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {isLoading && <Spinner dataHook="spinner" />}
      </div>
    </div>
  );
};
